<template>
  <div class="container mb mt">
    <h1>{{ $t(info.title) }}</h1>
    <div class="list">
      <ProductCard v-for="p in info.subs" :key="p.id" :p="p"  />
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 24px;
  margin-bottom: -4px;
}
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: min-content;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .list {
     grid-template-columns: repeat(1, 1fr);
  }
}
</style>

<script>
import ProductCard from '@/small-components/ProductCard.vue'
export default {
  props: ['info'],
  components: { ProductCard },
  data () {
    return {
      home: 'yes Mofos so'
    }
  }
}
</script>
