<template>
  <div>
    <Header :info="info" />
    <div class="tContainer">
      <div class="container" style="display:grid;place-items:center">
        <i class="far fa-check-circle"></i>
        <h1>{{ $t('Teşekkürler') }}</h1>
        <h2>{{ $t('Siparişiniz başarıyla tamamlandı') }}</h2>
        <p>{{ $t('Siparişiniz kargoya verildiğinde size kargo takip kodunu içeren bir email göndereceğiz') }}</p>
        <p>{{ $t('Ayrıca hesabım sayfasından siparişinizin güncel durumunu takip edebilirsiniz') }}</p>
        <p><router-link :to="{ name: 'MyAccountOrders' }">{{ $t('Sipariş Takibi') }}</router-link></p>
        <p><router-link :to="{ name: 'Index' }">{{ $t('Anasayfa') }}</router-link></p>
        <p>
          {{ $route.query.r }}
        </p>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.tContainer {
  min-height:calc(100vh - 382px);display:grid;place-items:center
}
i {
  font-size: 100px;
  color: green;
}
h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 20px;
}
p {
  margin-bottom: 8px;
  text-align: center;
}
a {
  color: blue;
  text-decoration: underline;
}
a:hover {
  color: var(--color1);
}
@media screen and (max-width:768px) {
  .tContainer {
    min-height:calc(100vh - 120px);display:grid;place-items:center
  }
}
</style>

<script>
import axios from 'axios'
export default {
  components: { },
  props: ['info'],
  data () {
    return {
      message: 1
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Teşekkürler'),
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  },
  beforeMount () {
    this.getPage()
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.parent = this.$route.params.parent
      this.pageType = this.$route.name
      this.page = null
      this.getPage()
    }
  },
  methods: {
    getPage: async function () {
      const payload = {
        id: parseInt(this.$route.params.id),
        parent: this.$route.params.parent,
        sId: this.$store.state.user.sId,
        pageType: this.pageType,
        userId: this.$store.state.user.userId
      }
      await axios.post('api/getPage.php', payload).then(response => {
        setTimeout(() => {
          this.page = response.data.page
          this.otherPages = response.data.otherPages
        }, 0)
      })
    }
  }
}
</script>
