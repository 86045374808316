<template>
  <div>
    <ul>
      <li class="cartItem" style="font-weight:bold">
        <span>Ürün Tanımı</span>
        <span style="text-align:right">Miktar</span>
        <span style="text-align:right">Tutar</span>
      </li>
      <li v-for="cartItem in items" :key="cartItem.id" class="cartItem">
        <span>{{ cartItem.title }}</span>
        <span style="text-align:right">{{ cartItem.qty }} Ad.</span>
        <span style="text-align:right">{{ cartItem.realPrice.toFixed(2) }} ₺</span>
      </li>
      <li v-if="summary.shipment" class="cartItem" style="font-weight:bold">
        <span></span>
        <span style="text-align:right">KARGO</span>
        <span style="text-align:right">{{ summary.shipment.toFixed(2) }} ₺</span>
      </li>
      <li class="cartItem" style="font-weight:bold; border-bottom:0">
        <span></span>
        <span style="text-align:right">TOPLAM</span>
        <span style="text-align:right">{{ (summary.totalPrice + summary.shipment).toFixed(2) }} ₺</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['items', 'summary']
}
</script>

<style scoped lang="scss">
.cartItem {
  display:grid;
  grid-template-columns: auto 70px 70px;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}
</style>
