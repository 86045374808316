<template>
  <div>
    <h1 v-if="page.title">{{ page.title }}</h1>
    <div v-for="(item, idx) in page.blocks" :key="idx" class="pageBlock">
      <h1 v-if="item.type === 'header' && item.data.level === 1">{{ item.data.text }}</h1>
      <h2 v-if="item.type === 'header' && item.data.level === 2">{{ item.data.text }}</h2>
      <h3 v-if="item.type === 'header' && item.data.level === 3">{{ item.data.text }}</h3>
      <div v-if="item.type === 'paragraph'">
        <p v-html="item.data.text"></p>
      </div>
      <ul v-if="item.type === 'list'">
        <li v-for="(listItem, idx) in item.data.items" :key="idx">{{ listItem }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['page'],
  data: function () {
    return {
      test: 'test'
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  font-size: 18px;
}
.pageBlock {
  h2 {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 15px;
  }
  p {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 28px;
  }
}
ul {
  li {
    margin-left: 20px;
    margin-bottom: 16px;
  }
}
</style>
