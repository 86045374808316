<template>
  <div>
    <div v-for="item in items" :key="item.id" class="sepetRow">
      <div style="text-align:center;">
        <router-link :to="item.url"><img style="padding:4px; border:1px solid #e0e0e0;" width="100%" :src="item.img"></router-link>
        <a class="sil" title="Sepetten Sil" @click="$parent.deleteFromCart(item.id)"><i class="fa fa-trash-alt"></i><span>{{ $t('Sepetten Sil') }}</span></a>
      </div>
      <div class="text">
        <div class="title">
          <div class="baslik"><router-link :to="item.url">{{ item.title }} </router-link></div>
          <div class="altBaslik" v-if="item.subTitle">{{ item.subTitle }}</div>
          <div v-if="item.printOptions">
            <ul class="specs">
              <li>
                <span>Baskı Tekniği</span>
                <span>{{ item.printTechnic.technicName }}</span>
              </li>
              <li v-for="(po, idx) in item.printOptions" :key="idx">
                <span>{{ po.tipName }}</span>
                <span>{{ po.name }}</span>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul class="specs">
              <li v-for="sp in  item.specs" :key="sp.id">
                <span>{{ sp.title }}</span>
                <span>{{ sp.value }} </span>
              </li>
            </ul>
          </div>
          <div class="actions">
            <div class="qty">{{ $tc('piece', item.qty) }}</div>
            <div class="price">{{ (item.realPrice).toFixed(2) }} ₺</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items']
}
</script>
