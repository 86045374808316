<template>
  <div>
    <Header :info="info" />
    <ProductDetail />
    <Footer :info="info" />
  </div>
</template>

<script>
import ProductDetail from '@/components/ProductDetail.vue'

export default {
  components: { ProductDetail },
  props: ['info']
}
</script>
