<template>
  <div class="info">
    <div class="sol">
      <h1 v-if="info.title">{{ info.title }}</h1>
    </div>
    <div class="sag"  v-if="$store.state.showPriceSorter">
      <label>Sırala</label>
      <select v-model="sort" @change="setSort">
        <option value="new">Yeni Gelenler</option>
        <option value="arf">Artan Fiyat</option>
        <option value="azf">Azalan Fiyat</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      sort: this.$store.state.sort
    }
  },
  methods: {
    setSort: function () {
      this.$store.dispatch('setSort', this.sort)
    }
  }
}
</script>

<style scoped lang="scss">
.info {
  border: 1px solid #ddd;
  background:#fff;
  border-radius:0px;
  display:grid;
  grid-template-columns: auto 200px;
  align-items:center;
  overflow: hidden;
  h1 {
    margin-left:8px;
    font-size:16px;
    font-weight:normal;
    color:#333;
  }
}
.sol {
  padding:7px;
}
.sag {
  padding:7px;
  display:grid;
  place-items:center;
  grid-template-columns: 40px auto;
  label {
    font-size: 12px;
  }
  select {
    width:100%;
    height:36px;
    border-radius:6px;
    border:1px solid #ddd;
    padding:8px
  }
}
@media screen and (max-width: 768px) {
  .info {
    grid-template-columns: 1fr;
  }
}
</style>
