<template>
  <div>
    <Header :info="info" />
    <Search />
    <Footer :info="info" />
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
export default {
  components: { Search },
  props: ['info']
}
</script>
