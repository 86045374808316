<template>
  <div class="slideContainer">
    <splide :options="$store.state.home.sliderOptions" v-if="info">
      <splide-slide v-for="item in info.subs" :key="item.id">
        <div :style="'background:url(' + fileCdn + item.source + ') no-repeat;'" class="slide">
          <div class="content">
            <h1>{{ item.title }}</h1>
            <p>{{ item.body }}</p>
            <router-link :title="item.sUrlText" v-if="item.sUrl" :to="item.sUrl">
              {{ item.sUrlText }}
            </router-link>
          </div>
        </div>
      </splide-slide>
    </splide>
  </div>
</template>

<style scoped lang="scss">
.slideContainer {
  background-color: black;
}
.slide {
  background-position:center !important;
  height:100%;
  background-size: cover !important;
  margin-left: -0.1px;
  margin-right: 0.1px;
  background-color: black;
}
.content {
  width:550px;
  margin:auto;
  display:grid;
  justify-items:center;
  color: #f1f1f1;
  padding-top:calc(10%);
  font-family: var(--font3);
}
h1 {
  font-size: 36px;
  text-transform:uppercase
}
p {
  text-align: center;
  font-size: 19px;
  line-height: 30px;
  margin-top: 20px;
}
a {
  margin-top: 20px;
  background: var(--color1);
  color:white;
  padding-left:22px;
  padding-right:22px;
  height: 50px;
  display:flex;
  place-items: center;
  border-radius: 40px;
  font-family: var(--font3);
  font-weight: 600;
  font-size: 16px;
  transition: all 0.1s ease-in;
}
a:hover {
  background: var(--color3);
}
@media screen and (max-width:768px) {
  .content {
    width: 80%;
    padding-top: 20%;
  }
  h1 {
    font-size:26px;
  }
  p {
    font-size: 16px;
  }
  a {
    font-size: 14px;
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      fileCdn: this.$store.state.fileCdn
    }
  }
}
</script>
