<template>
  <div>
    <Header :info="info" />
    <div class="container">
      <BreadCrumb :info="[{ id:0, title: $t('Hesabım') }]" />
      <div class="catalog" v-if="$store.state.user.userId">
        <div class="filters">
          <div class="block">
            <b>{{ $t('Hesabım') }}</b>
            <ul>
              <li v-for="item in pages" :key="item.title">
                <router-link :to="{ name: item.route }">{{ $t(item.title) }}</router-link>
              </li>
            </ul>
          </div>
          <button @click="logout" type="button"><i class="fa fa-lock"></i>{{ $t('Çıkış Yap') }}</button>
        </div>
        <div style="min-height:calc(100vh - 400px)">
          <h2 style="margin-bottom:15px">{{ $t($route.meta.title) }}</h2>
          <div v-if="isLoading">
            <img src="@/assets/loading-pulse.svg">
          </div>
          <div v-else style="display:grid;">
            <div v-if="$route.name === 'MyAccount'" style="width:100%;">
              <div class="block" style="border:none;">
                <ul style="padding-left:0px;">
                  <li v-for="item in pages" :key="item.title" style="padding:4px; border-bottom:1px solid #ccc">
                    <router-link style="font-size:14px;font-weight:600" :to="{ name: item.route }">{{ $t(item.title) }}</router-link>
                  </li>
                </ul>
              </div>
              <button @click="logout" type="button"><i class="fa fa-lock"></i>{{ $t('Çıkış Yap') }}</button>
            </div>
            <Orders v-if="routeName === 'MyAccountOrders'" :info="compData" />
            <UserInfo v-if="routeName === 'MyAccountProfile'" :info="compData" />
            <ChangePassword v-if="routeName === 'MyAccountPassword'" :info="compData" />
          </div>
        </div>
      </div>
      <div v-else class="catalog" style="grid-template-columns:1fr">
        <div>
          <p><b>{{ $t('Bu sayfayı görebilmek için giriş yapmalısınız') }}</b></p>
          <p style="margin-top:12px;"><router-link :to="{ name: 'Login' }">{{ $t('Giriş yapmak için tıklayın') }}</router-link></p>
        </div>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<script>
import axios from 'axios'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import Orders from '@/account-components/Orders'
import UserInfo from '@/account-components/UserInfo'
import ChangePassword from '@/account-components/ChangePassword'
export default {
  components: { BreadCrumb, Orders, UserInfo, ChangePassword },
  props: ['info'],
  data () {
    return {
      routeName: this.$route.name,
      isLoading: true,
      compData: null,
      pages: [
        { title: 'Siparişlerim', route: 'MyAccountOrders' },
        { title: 'Üyelik Bilgilerim', route: 'MyAccountProfile' },
        // { title: 'Bildirim Tercihlerim', route: 'MyAccountPermissions' },
        { title: 'Şifrenizi Değiştirin', route: 'MyAccountPassword' }
      ]
    }
  },
  metaInfo () {
    const title = this.$i18n.t(this.$route.meta.title)
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: '' }, { vmid: 'robots', name: 'description', content: 'noindex' }]
    }
  },
  watch: {
    $route (to, from) {
      this.routeName = this.$route.name
      this.getInfo()
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    getInfo: async function () {
      this.isLoading = true
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('/api/' + this.routeName + '.php', payload).then(response => {
        const data = response.data
        const err = data.error
        if (err === 'noUser') {
          this.$store.dispatch('setUser', data.user)
        }
        if (err === 'noSess') {
          this.$store.dispatch('setUser', data.user)
        }
        this.compData = data
        this.isLoading = false
      })
    },
    logout: async function () {
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('/api/logout.php', payload).then(response => {
        this.$store.dispatch('setUser', response.data.user)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.otherPages {
  grid-template-columns: 1fr !important;
}
.block {
  font-size: 13px;
  line-height: 22px;
  b {
    a {
      font-size: 13px;
      color:#666;
    }
    a:hover, .active {
      color:var(--color1);
    }
  }
  p {
    font-weight: bold;
    font-size: 15px;
  }
  ul {
    user-select: none;
    list-style:none;
    padding-left: 12px;
    li{
      a {
        font-size: 13px;
        color:#666;
        display:block;
        padding:4px;
      }
      a:hover, .active {
        color:var(--color1);
      }
    }
  }
}
.catalog {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh - 500px);
  .filters {
    border-right:1px solid #ddd;
    padding-right:20px;
    user-select: none;
  }
  button {
    border: 1px solid var(--color1);
    outline: none;
    background: var(--color1);
    color: #fff;
    padding: 7px 2px 7px 2px;
    width: 100%;
    transition: all 0.1s ease-in;
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    text-align: left;
    font-size: 12px;
    i {
      text-align: center;
      font-size: 13px;
    }
  }
  button:hover {
    background:#333;
    border-color: #333;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>
