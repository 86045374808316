<template>
  <div>
    <h1 v-if="page.title">{{ page.title }}</h1>
    <div v-for="(item, idx) in page.blocks" :key="idx" class="pageBlock">
      <h1 v-if="item.type === 'header' && item.data.level === 1">{{ item.data.text }}</h1>
      <h2 v-if="item.type === 'header' && item.data.level === 2">{{ item.data.text }}</h2>
      <h3 v-if="item.type === 'header' && item.data.level === 3">{{ item.data.text }}</h3>
      <div v-if="item.type === 'paragraph'">
        <CartItemsCompact class="contactCard" v-if="item.data.text === '{urunler}'" :items="$parent.$parent.$parent.items" :summary="$parent.$parent.$parent.summary" />
        <ContactCard class="contactCard" v-else-if="item.data.text === '{saticiBilgiler}'" :data="seller" />
        <ContactCard class="contactCard" v-else-if="item.data.text === '{aliciBilgiler}'" :data="buyer"/>
        <p v-else>{{ item.data.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CartItemsCompact from '@/small-components/CartItemsCompact'
import ContactCard from '@/small-components/ContactCard'
export default {
  components: { CartItemsCompact, ContactCard },
  props: ['page'],
  data: function () {
    const info = this.$store.state.info.companyInfo
    const user = this.$store.state.user
    const adres = this.$parent.$parent.invoiceAddress
    let buyerUnvan = ''
    if (adres.unvan === '') {
      buyerUnvan = adres.ad
    } else {
      buyerUnvan = adres.unvan
    }
    return {
      seller: [
        { title: 'Ünvan', value: info.PAGE_UNVAN },
        { title: 'Adres', value: info.PAGE_ADRES },
        { title: 'Telefon', value: info.PAGE_PHONE },
        { title: 'E-Posta', value: info.PAGE_EPOSTA }
      ],
      buyer: [
        { title: 'Ünvan', value: buyerUnvan },
        { title: 'Adres', value: adres.adres + ' ' + adres.ilce + ' / ' + adres.il },
        { title: 'Telefon', value: this.$fnc.phone(adres.tel) },
        { title: 'E-Posta', value: user.email }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.contactCard {
  margin-bottom:12px;
  font-size:13px;
  line-height:22px;
  padding:12px;
  background:#f5f5f5;
  border-radius: 8px;
  border: 2px dashed #ccc;
}
h1 {
  font-size: 18px;
}
.pageBlock {
  h2 {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 15px;
  }
  p {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 28px;
  }
}
</style>
