<template>
  <div style="background:#E5E6EA;height:100vh; overflow:auto;">
    <SimpleHeader :info="info" style="padding-top:20px" />
    <Cart :mode="'cart'"/>
    <SimpleFooter :info="info" />
  </div>
</template>

<script>
import Cart from '@/components/Cart.vue'

export default {
  components: { Cart },
  props: ['info']
}
</script>
