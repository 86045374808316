<template>
  <div>
    <div v-for="(item, idx) in data" :key="idx" style="display:grid; grid-template-columns:60px auto; gap:10px">
      <span style="text-align:right">{{ item.title }}:</span>
      <span>{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>
