<template>
  <div>
    <div class="container">
      <BreadCrumb :info="breadCrumb" />
      <div class="catalog" :class="{ catalogNoFilter: !showFilters }">
        <div class="products">
          <CatInfo :info="{ totalProducts: totalProducts, title: `'` + $route.params.search + `' ile ilgili ` + totalProducts + ` sonuç var` }" />
          <div class="list" style="margin-top:20px;">
            <ProductCard v-for="p in products" :key="p.id" :p="p"  />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import CatInfo from '@/small-components/CatInfo.vue'
import ProductCard from '@/small-components/ProductCard.vue'

export default {
  components: { BreadCrumb, CatInfo, ProductCard },
  data () {
    return {
      products: [],
      search: this.$route.params.search,
      page: parseInt(this.$route.query.p),
      sort: this.$store.state.sort,
      totalProducts: 0,
      pageSize: this.$store.state.pageSize,
      pageRange: this.$fnc.pageRange(0, { min: 1, total: 1, length: 1 }),
      showFilters: false,
      breadCrumb: []
    }
  },
  watch: {
    $route (to, from) {
      this.search = this.$route.params.search
      if (!this.search) {
        this.$router.push({ name: 'Index' })
      }
      this.page = parseInt(this.$route.query.p)
      this.getProducts()
    },
    '$store.state.sort': function () {
      this.sort = this.$store.state.sort
      if (this.page === 1) {
        this.getProducts()
      } else {
        this.$router.push({
          name: 'Products', query: { brands: this.brands, filters: this.filters }
        })
      }
    }
  },
  beforeMount () {
    this.getProducts()
  },
  methods: {
    getProducts: async function () {
      this.products = []
      var page = 1
      if (this.page) {
        page = parseInt(this.page)
      } else {
        this.page = 1
      }
      const payload = {
        search: this.$route.params.search,
        page: page,
        sort: this.sort,
        pageSize: this.pageSize,
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId
      }
      await axios.get('api/products.php', { params: payload }).then(response => {
        const data = response.data
        this.products = data.catalog
        this.totalProducts = data.total
        this.cat = data.cat
        this.pageSize = data.pageSize
        var length = 10
        if (this.$fnc.getWidth() < this.$store.state.mobileWidth) {
          length = 6
        }
        this.pageRange = this.$fnc.pageRange(this.page, { min: 1, total: Math.ceil(data.total / data.pageSize), length: length })
        this.breadCrumb = data.bc
      })
    }
  }
}
</script>
<style scoped lang="scss">
.products {
  min-height: 800px;
}
ul.subs {
  user-select: none;
  list-style:none;
  li{
    a {
      font-size: 13px;
      color:#666;
    }
    a:hover, .active {
      color:var(--color1);
    }
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-top: 1px;
        padding-bottom: 1px;
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.catalog {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 20px;
  margin-bottom: 30px;
  .filters {
    border-right:1px solid #ddd;
    padding-right:20px;
    .block {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      p {
        font-weight: bold;
        font-size: 15px;
      }
      ul {
        list-style:none;
      }
    }
  }
}
.catalogNoFilter {
  grid-template-columns: 1fr;
}
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  min-height: 1800px;
  min-height: 400px;
  grid-auto-rows: min-content;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .list {
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>
