<template>
  <div>
    <Header :info="info" />
    <div class="tContainer">
      <div class="container" style="display:grid;place-items:center">
        <i class="fa fa-minus-circle"></i>
        <h1>{{ $t('404') }}</h1>
        <h2>{{ $t('Sayfa bulunamadı :/') }}</h2>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.tContainer {
  min-height:calc(100vh - 382px);display:grid;place-items:center
}
i {
  font-size: 100px;
  color: red;
}
h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 20px;
}
p {
  margin-bottom: 8px;
  text-align: center;
}
a {
  color: blue;
  text-decoration: underline;
}
a:hover {
  color: var(--color1);
}
@media screen and (max-width:768px) {
  .tContainer {
    min-height:calc(100vh - 120px);display:grid;place-items:center
  }
}
</style>

<script>
export default {
  components: { },
  props: ['info'],
  data () {
    return {
      message: 1
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Sayfa Bulunamadı'),
      meta: [
        { vmid: 'description', name: 'description', content: '' },
        { vmid: 'robots', name: 'description', content: 'noindex' }
      ]
    }
  }
}
</script>
