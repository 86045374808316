<template>
  <div style="display:grid;place-items:center;margin-top:30px" v-if="totalProducts > 0">
    <ul class="pager">
      <li v-if="page > 1">
        <router-link :title="$t('Önceki Sayfa')" rel="prev" v-if="(page - 1) === 1"
        :to="{ name: 'Products', query: {
          brands: brands,
          filters: filters
        }}"><i class="fa fa-angle-double-left"></i></router-link>
        <router-link :title="$t('Önceki Sayfa')" rel="prev" v-else
        :to="{ name: 'Products', query: {
          brands: brands,
          filters: filters,
          p: (page - 1)
        }}"><i class="fa fa-angle-double-left"></i></router-link>
      </li>
      <li v-else>
        <span class="passive"><i class="fa fa-angle-double-left"></i></span>
      </li>
      <li v-for="(i, idx) in pageRange" :key="idx">
        <router-link v-if="i === 1"
        :to="{ name: 'Products', query: {
          brands: brands,
          filters: filters
        }}"
        :class="{ activePage: page === i }">{{ i }}</router-link>
        <router-link v-else :rel="((page + 1) === (i)) ? 'next' : false"
        :to="{ name: 'Products', query: {
          brands: brands,
          filters: filters,
          p: i
        }}"
        :class="{ activePage: page === i }">{{ i }}</router-link>
      </li>
      <li v-if="page < Math.ceil(totalProducts / pageSize)">
        <router-link :title="$t('Sonraki Sayfa')" rel="next"
        :to="{ name: 'Products', query: {
          brands: brands,
          filters: filters,
          p: (page + 1)
        }}"><i class="fa fa-angle-double-right"></i></router-link>
      </li>
      <li v-else>
        <span class="passive"><i class="fa fa-angle-double-right"></i></span>
      </li>
    </ul>
    <div style="font-size:14px;margin-top:8px">
      {{ $tc('result_pages', Math.ceil(totalProducts / pageSize)) }} {{ $tc('result_products', totalProducts) }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'title', 'brands', 'filters', 'page', 'pageRange', 'totalProducts', 'pageSize']
}
</script>

<style scoped lang="scss">
.pager {
  list-style:none;
  display:flex;
  margin-top: 10px;
  margin-bottom: 10px;
  li {
    margin-left:3px;
    margin-right:3px;
  }
  li a{
    padding-left:10px;
    padding-right:10px;
    padding-top:5px;
    padding-bottom:5px;
    border-radius: 6px;
    cursor:pointer;
    user-select: none;
    -webkit-user-drag:none;
    color: #000;
    text-align: center;
  }
  li a:hover {
    background:#4d4d4d;
    color: white;
  }
  li a.activePage {
    background: var(--color1);
    color:white;
  }
  li span.passive {
    color:#ccc;
    cursor: default;
    padding-left:10px;
    padding-right:10px;
  }
  li span.passive:hover {
    background: none;
    color:#ccc;
  }
}
</style>
