<template>
  <div>
    <a :href="'tel:' + $store.state.info.companyInfo.PAGE_PHONE2">
      <i class="fa fa-phone-alt"></i>
      <span>{{ $t('Detaylı bilgi ve sipariş için bizi arayın') }}</span>
    </a>
  </div>
</template>

<style scoped lang="scss">
a {
  border:none;
  background-color: var(--color1);
  color:white;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 30px auto;
  gap: 5px;
  align-items: center;
  font-size:15px;
  font-weight: bold;
  font-family: var(--font2);
  i {
    text-align: center;
  }
}

a:hover {
  background: var(--color3);
}
</style>

<script>
export default ({
  props: ['specs']
})
</script>
