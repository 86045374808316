<template>
  <div v-if="info.orders">
    <div class="row" v-for="item in info.orders" :key="item.faturaId" :class="{ 'activeRow': item.faturaId === activeOrder}">
      <div class="faturaRow" @click="selectActive(item.faturaId)">
        <div class="grid2">
          <div>{{ item.faturaNo }}</div>
          <div class="right">{{ item.faturaTarih }}</div>
        </div>
        <div class="grid2">
          <div>{{ item.faturaDurum }}</div>
          <div class="right">{{ item.faturaTutar.toFixed(2) }}₺</div>
        </div>
        <div class="buttonSet right" style="width:30px">
          <button v-if="activeOrder !== item.faturaId" style="background:teal;color:white"><i class="fa fa-angle-down"></i></button>
          <button v-else><i class="fa fa-times"></i></button>
        </div>
      </div>
      <div class="faturaDetails" v-if="activeOrder === item.faturaId">
        <div class="items">
          <ul style="">
            <li v-for="stock in item.items" :key="stock.id">
              <div class="ustRow">
                <span style="padding:12px">
                  <router-link :to="{ name: 'Product', params: { pid: stock.pid + '-' +$fnc.seo(stock.title) } }">
                    <img v-if="stock.image" class="ovalImage" :src="cdn + stock.image" width="100%">
                    <img v-else class="ovalImage" :src="cdn + '1200_no-image.png'" width="100%">
                  </router-link>
                </span>
                <span><router-link :to="{ name: 'Product', params: { pid: stock.pid + '-' +$fnc.seo(stock.title) } }" v-html="stock.title">{{ stock.title }}</router-link></span>
                <span style="text-align:right">{{ stock.qty }} {{ $t('Ad.') }}</span>
                <span style="text-align:right">{{ stock.tutar.toFixed(2) }} ₺</span>
              </div>
              <div class="altRow" style="padding-bottom:12px" v-if="stock.options">
                <span style="margin-right:12px;font-weight:bold;font-size:12px" v-for="option in stock.options" :key="option.id">
                  <span>{{ option.tipName}}: </span>
                  <span>{{ option.name}}, </span>
                </span>
                <div style="margin-top:16px">
                  <button @click="openModal(stock.id)" style="background:red; color:white; border:none;padding:8px;border-radius:4px;"><i class="fa fa-upload"></i> Tasarımını Yükle</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <GlassModal v-if="showFileUploader" @close="closeModal">
      <template v-slot:title>Dosyalarınızı yükleyin</template>
      <template v-slot:body>
        <p>Dosyalarınızı lütfen <b>PDF</b>, <b>Aİ</b> veya <b>PSD</b> formatında yükleyin</p>
        <div class="filePicker">
          <input type="file">
          <button><i class="fa fa-upload"></i> Dosyayı Yükle</button>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.mediaCdn,
      activeOrder: null,
      showFileUploader: false,
      stockId: null
    }
  },
  mounted () {
    // this.selectActive(this.info.orders[0].faturaId)
  },
  methods: {
    openModal: function (stockId) {
      this.showFileUploader = true
      this.stockId = stockId
    },
    closeModal: function () {
      this.showFileUploader = false
      this.stockId = null
    },
    selectActive: function (orderNo) {
      if (this.activeOrder === orderNo) {
        this.activeOrder = null
      } else {
        this.activeOrder = orderNo
      }
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.filePicker {
  margin-top:6px;
  padding: 12px;
  background:#e5e5e5;
  border-radius:6px;
  display:grid;
  grid-template-columns:auto 120px;
  gap:10px;
  place-items:center;
  input {
    width:100%;
  };
  button {
    font-size: 12px;
    width: 100%;
    padding:9px;
    border:none;
    border:1px solid #ccc;
    background:#fff;
    border-radius:6px;
    transition: all 0.1s ease-out;
  }
  button:hover {
    background: var(--color2);
    color:white;
  }
}
a {
  color:black;
  user-select: unset;
  user-select: text !important;
}
a:hover {
  color: var(--color1);
}
.ovalImage {
  padding:1px; border:1px solid #ccc;border-radius:50%;background:white;
  user-select: none;
  -webkit-user-drag: none;
}
.grid2 {
  display:grid;
  grid-template-columns: auto auto;
  .right {
    text-align:right;
  }
}
.buttonSet {
  text-align:center;
  button {
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border: none;
    background: #f1f1f1;
    border-radius: 50%;
  }
  button:hover {
    background: #e0e0e0;
  }
}
.row {
  // border: 1px solid #ccc;
  margin-bottom: 22px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  transition: all 0.1s ease-out;
}
.row:hover, .activeRow {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.faturaRow {
  padding: 10px;
  align-items: center;
  display:grid;
  grid-template-columns: 240px auto 40px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  background: #fff;
  overflow: hidden;
  border-radius: 6px;
  div {
    padding: 8px;
  }
}
.items {
  background:#f3f3f3;
  border-radius: 6px;
  ul {
    list-style: none;
    padding: 0px;
    li {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 20px;
      .ustRow {
        display: grid;
        grid-template-columns: 100px auto 60px 60px;
        align-items: center;
        min-height: 90px;
      }
    }
    li:nth-child(even) {
       background: white;
    }
  }
}
@media screen and (max-width: 768px) {
  .faturaRow {
    grid-template-columns: 1fr;
  }
  .ustRow {
    grid-template-columns: 60px auto 60px 60px !important;
  }
}
</style>
