<template>
  <div class="card">
    <img v-if="info.src" :alt="info.title" :src="cdn + info.src">
    <div class="text">
      <div>
        <h3>{{ info.title }}</h3>
        <p>{{ info.desc }}</p>
        <router-link style="margin-top:42px" :to="{ name: 'Page', params: { id: info.id + '-' + $fnc.seo(info.title), parent: 0 }}">Devamını Oku</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  padding: 0px;
  background:#fff;
  margin: auto;
  border-radius: 14px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 6fr;
  gap: 20px;
  .text {
    display: grid;
    align-items: center;
    width: calc(100% - 20px);
  }
  h3 {
    margin-bottom: 4px;
    font-size: 28px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 22px;
  }
  img {
    width:100%;
  }
  a {
    color: var(--color1);
  }
  a:hover {
    color: var(--color3);
  }
}
@media screen and (max-width: 768px) {
  .card {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
  .text {
    padding-left: 20px;
  }
}
</style>

<script>
export default ({
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.fileCdn
    }
  }
})
</script>
