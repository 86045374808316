<template>
  <div>
    <h1 v-if="page.title">{{ page.title }}</h1>
    <div class="imageContainer">
      <div v-for="item in items" :key="item.id">
        <img :src="fileCdn + item.images[0].source" :alt="item.page.title" :title="item.page.title" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['page'],
  data: function () {
    return {
      items: [],
      fileCdn: this.$store.state.fileCdn
    }
  },
  mounted: function () {
    this.getItems()
  },
  methods: {
    getItems: async function () {
      const parentId = parseInt(this.$route.params.id)
      const payload = {
        parentId: parentId
      }
      await axios.post('api/getSubPages.php', payload).then(response => {
        this.items = response.data.pages
      })
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  font-size: 18px;
  margin-bottom: 20px;
}
.imageContainer {
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  div {
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    place-items: center;
    background: #fff;
    padding: 12px;
    img {
      width:100%
    }
  }
}

@media screen and (max-width: 1200px) {
  .imageContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .imageContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
