<template>
  <div>
    <HeroParallax v-if="!hero" :info="hero" />
    <div class="container">
      <BreadCrumb :info="breadCrumb" :jsonld="bcJsonld" />
      <div class="catalog" :class="{ catalogNoFilter: !showFilters }">
        <div class="filters" v-if="showFilters">
          <div class="block" v-if="subCats.length > 0">
            <ul v-for="item in subCats" :key="item.id" class="subs">
              <li>
                <router-link style="font-weight:bold"
                  :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }}
                </router-link>
              </li>
              <li v-for="item2 in item.subs" :key="item2.id" style="padding-left:12px">
                <div v-if="item2.title !== 'sütun'">
                  <router-link style="font-weight:bold"
                    :to="{ name: 'Products', params: { id: item2.id + '-' + $fnc.seo(item2.title), top: item.id }}">{{ item2.title }}
                  </router-link>
                </div>
                <ul style="padding-left:8px">
                  <li v-for="item3 in item2.subs" :key="item3.id" style="margin-bottom:12px">
                    <router-link style="font-weight:bold"
                      :to="{ name: 'Products', params: { id: item3.id + '-' + $fnc.seo(item3.title), top: item.id }}">{{ item3.title }}
                    </router-link>
                    <ul style="padding-left:8px">
                      <li v-for="item4 in item3.subs" :key="item4.id">
                        <router-link
                          :to="{ name: 'Products', params: { id: item4.id + '-' + $fnc.seo(item4.title), top: item.id }}">{{ item4.title }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="block" v-if="allBrands.length > 0">
            <p>{{ $t('Markalar') }}</p>
          </div>
          <div class="block" v-if="allFilters.length > 0">
            <p>{{ $t('Seçenekler') }}</p>
          </div>
        </div>
        <div class="products">
          <CatInfo :info="{ totalProducts: totalProducts, title: cat.title }" />
          <div v-if="subCats">
            <ul v-for="item in subCats" :key="item.id" class="subs" style="display:flex;">
              <li v-for="item2 in item.subs" :key="item2.id" style="margin-top:12px;margin-right:16px">
                <div v-if="item2.title !== 'sütun'">
                  <router-link style="font-weight:bold;"
                    :to="{ name: 'Products', params: { id: item2.id + '-' + $fnc.seo(item2.title), top: item.id }}">{{ item2.title }}
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
          <div class="list" style="margin-top:20px;">
            <ProductCard v-for="p in products" :key="p.id" :p="p"  />
          </div>
          <Pager :id="id" :brands="brands" :filters="filters" :page="page" :pageRange="pageRange" :totalProducts="totalProducts" :pageSize="pageSize"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import HeroParallax from '@/small-components/HeroParallax.vue'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import CatInfo from '@/small-components/CatInfo.vue'
import ProductCard from '@/small-components/ProductCard.vue'
import Pager from '@/small-components/Pager.vue'

export default {
  components: { HeroParallax, BreadCrumb, CatInfo, ProductCard, Pager },
  data () {
    return {
      products: [],
      id: this.$route.params.id,
      brands: this.$route.query.brands,
      filters: this.$route.query.filters,
      page: parseInt(this.$route.query.p),
      sort: this.$store.state.sort,
      totalProducts: 0,
      pageSize: this.$store.state.pageSize,
      pageRange: this.$fnc.pageRange(0, { min: 1, total: 1, length: 1 }),
      cat: {},
      showFilters: false,
      subCats: [],
      allBrands: [],
      allFilters: [],
      breadCrumb: [],
      bcJsonld: null,
      hero: {}
    }
  },
  metaInfo () {
    let title = this.cat.pageTitle
    if (!title) {
      title = this.cat.title
    }
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: this.cat.body }]
    }
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.brands = this.$route.query.brands
      this.filters = this.$route.query.filters
      this.page = parseInt(this.$route.query.p)
      this.getProducts()
    },
    '$store.state.sort': function () {
      this.sort = this.$store.state.sort
      if (this.page === 1) {
        this.getProducts()
      } else {
        this.$router.push({
          name: 'Products', query: { brands: this.brands, filters: this.filters }
        })
      }
    }
  },
  beforeMount () {
    this.getProducts()
  },
  methods: {
    getProducts: async function () {
      this.products = []
      var page = 1
      if (this.page) {
        page = parseInt(this.page)
      } else {
        this.page = 1
      }
      const payload = {
        id: parseInt(this.$route.params.id),
        brands: this.$route.query.brands,
        filters: this.$route.query.filters,
        page: page,
        sort: this.sort,
        pageSize: this.pageSize,
        top: parseInt(this.$route.params.top),
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId
      }
      await axios.get('api/products.php', { params: payload }).then(response => {
        const data = response.data
        this.products = data.catalog
        this.totalProducts = data.total
        this.cat = data.cat
        this.pageSize = data.pageSize
        var length = 10
        if (this.$fnc.getWidth() < this.$store.state.mobileWidth) {
          length = 6
        }
        this.pageRange = this.$fnc.pageRange(this.page, { min: 1, total: Math.ceil(data.total / data.pageSize), length: length })
        this.subCats = data.subMenus
        this.breadCrumb = data.bc
        let headerImage = ''
        let pageTitle = data.cat.title
        if (!data.catImage) {
          headerImage = this.$store.state.info.base + '/src/assets/header.png'
        } else {
          headerImage = this.$store.state.fileCdn + data.catImage
        }
        if (this.cat.pageTitle) {
          pageTitle = this.cat.pageTitle
        }
        this.bcJsonld = {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: data.breadcrumbItems
        }
        this.hero = {
          title: pageTitle,
          text: this.cat.body,
          color: this.cat.sUrlText ?? '#FFF',
          src: headerImage,
          show: true
        }
      }).then(response => {
        this.$fnc.pageView(this)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.products {
  min-height: 800px;
}
ul.subs {
  user-select: none;
  list-style:none;
  li{
    a {
      font-size: 13px;
      color:#666;
    }
    a:hover, .active {
      color:var(--color1);
    }
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-top: 1px;
        padding-bottom: 1px;
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.catalog {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 20px;
  margin-bottom: 30px;
  .filters {
    border-right:1px solid #ddd;
    padding-right:20px;
    .block {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      p {
        font-weight: bold;
        font-size: 15px;
      }
      ul {
        list-style:none;
      }
    }
  }
}
.catalogNoFilter {
  grid-template-columns: 1fr;
}
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  min-height: 1800px;
  min-height: 400px;
  grid-auto-rows: min-content;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
</style>
