<template>
  <div class="block">
    <div v-if="user.userId">
      <div class="tabs">
        <i class="fa fa-user"></i>
        <button class="activeTab" style="cursor:default">{{ $t('Üyelik Bilgileri') }}</button>
      </div>
      <div class="formRow">
        <p>{{ user.userName }}</p>
        <p>{{ user.email }}</p>
      </div>
      <div class="formRow">
        <div>
          <button @click="$parent.logout">{{ $t('Çıkış Yap') }}</button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="tabs">
        <i class="fa fa-user"></i>
        <button :class="{ 'activeTab': form === 'register'}" @click="toggleForm('register')">{{ $t('Üye Olun') }}</button>
        <button :class="{ 'activeTab': form === 'login'}" @click="toggleForm('login')">{{ $t('Giriş Yapın') }}</button>
      </div>
      <div v-if="form === 'login'">
        <form method="dialog">
          <div v-if="error" class="formError"> {{ $t(error) }} </div>
          <div class="formRow">
            <div><label>{{ $t('E-Posta Adresiniz') }}</label></div>
            <div><input v-model="login.email" autocomplete="email" type="email" :placeholder="$t('E-Posta Adresiniz')"></div>
          </div>
          <div class="formRow">
            <div><label>{{ $t('Parolanız') }}</label></div>
            <div><input v-model="login.pass" autocomplete="password" type="password" :placeholder="$t('Parolanız')"></div>
          </div>
          <div class="formRow" style="display:flex; gap:14px">
            <button @click="$parent.login(login)" class="btn1"><i class="fa fa-list-alt"></i> <span>{{ $t('Giriş Yapın') }}</span></button>
          </div>
        </form>
      </div>
      <div v-if="form === 'register'">
        <form method="dialog">
          <div v-if="error" class="formError"> {{ $t(error) }} </div>
          <div class="formRow">
            <div><label>{{ $t('Adınız Soyadınız') }}</label></div>
            <div><input v-model="login.name" autocomplete="name" type="text" :placeholder="$t('Adınız Soyadınız')"></div>
          </div>
          <div class="formRow">
            <div><label>{{ $t('E-Posta Adresiniz') }}</label></div>
            <div><input v-model="login.email" autocomplete="email" type="email" :placeholder="$t('E-Posta Adresiniz')"></div>
          </div>
          <div class="formRow">
            <div><label>{{ $t('Parolanız') }}</label></div>
            <div><input v-model="login.pass" autocomplete="password" type="password" :placeholder="$t('Parolanız')"></div>
          </div>
          <div class="checker" id="registercontract">
            <a @click="setContract">
              <i :class="[contract ? 'far fa-check-square' : 'far fa-square']"></i> {{ $t('Üyelik sözleşmesini kabul ediyorum') }}
            </a>
          </div>
          <div class="checker">
            <a @click="setMessagePermission">
              <i :class="[messagePermission ? 'far fa-check-square' : 'far fa-square']"></i> {{ $t('Kampanya ve haberlerden bilgi sahibi olmak istiyorum') }}
            </a>
          </div>
          <div class="formRow" style="display:flex; gap:14px">
            <button @click="$parent.register(login, contract, messagePermission)" class="btn1"><i class="fa fa-list-alt"></i> <span>{{ $t('Üye Olun') }}</span></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: 'register',
      login: {
        name: '',
        email: '',
        pass: ''
      },
      contract: true,
      messagePermission: false
    }
  },
  props: ['user', 'error'],
  methods: {
    setContract: function () {
      this.contract = !this.contract
    },
    setMessagePermission: function () {
      this.messagePermission = !this.messagePermission
    },
    toggleForm: function (form) {
      this.$parent.loginError = false
      this.form = form
    }
  }
}
</script>

<style scoped lang="scss">
.checker {
  font-size:13px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.tabs {
  display:flex;
  margin-bottom: 4px;
  gap: 12px;
  i {
    width:24px;
    display: none;
  }
  button {
    border:none;
    background:none;
    border-bottom: 3px solid white;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    transition: all 0.1s ease-in;
  }
  .activeTab {
    border-bottom: 3px solid var(--color1);
  }
}
.formError {
  padding:8px;
  font-size: 13px;
  border:2px dashed orangered;
  border-radius: 8px;
  margin-top: 8px;
}
.formRow {
  .btn1 {
    padding:10px;
    border-radius: 5px;
    border:none;
    background: var(--color1);
    color: white;
    font-weight: normal;
    font-family: var(--font3);
    font-size: 14px;
    letter-spacing: 1px;
  }
  .passive {
    filter: brightness(1.4);
  }
  .btn1:hover {
    border:none;
    filter: brightness(1.15);
  }
}
</style>
