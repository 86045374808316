<template>
  <div>
    <div v-for="item in info" :key="item.id">
      <Slider v-if="item.component === 'slider'" :info="item" />
      <Flex v-if="item.component === 'flex'" :info="item" />
      <Grid v-if="item.component === 'grid'" :info="item" />
      <Collection v-if="item.component === 'collection'" :info="item" />
    </div>
  </div>
</template>

<script>
import Flex from '../home-components/Flex'
import Grid from '../home-components/Grid'
import Collection from '../home-components/Collection'
import Slider from '../home-components/Slider'

export default {
  components: {
    Flex,
    Grid,
    Collection,
    Slider
  },
  props: ['info'],
  data () {
    return {
      home: 'yes Mosfos so'
    }
  }
}
</script>
