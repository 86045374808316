<template>
  <div>
    <div class="container" style="min-height:640px" v-if="!isLoading && !error">
      <BreadCrumb :info="breadCrumb" />
      <Bcjsonld :jsonld = "jsonld" />
      <div class="pContainer">
        <div>
          <img style="margin-bottom:30px;border-radius:6px;background:#fff" v-for="image in product.images" :key="image.id" :src="mediaCdn + '/1200_' + image" width="100%">
        </div>
        <div>
          <div style="position:sticky; top:20px">
            <NormalProduct2 :product="product" v-if="productType === 'normal'" />
          </div>
        </div>
      </div>
      <div v-if="product.relatedProducts.subs">
        <h3>{{ product.relatedProducts.title }}</h3>
        <router-link
          v-for="item in product.relatedProducts.subs" :key="item.p.urunlerId"
          :to="{ name: 'Product', params: { pid: item.p.urunlerId + '-' + $fnc.seo(item.p.urunlerAdi) }}"
          class="yedekRow">
          <img :alt="item.p.urunlerAdi" v-if="item.images[0]" width="120" :src="mediaCdn + '250_' +item.images[0].source">
          <img v-else width="120" height="100" style="background:#fff;border:none">
          <p>{{ item.p.urunlerAdi }}</p>
        </router-link>
      </div>
    </div>
    <div v-else style="min-height:640px; display:grid;place-items:center">
      <img src="~@/assets/loading-pulse.svg">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import NormalProduct2 from '@/components/NormalProduct2.vue'
// import ProductSpecs from '@/small-components/ProductSpecs.vue'
// import ProductImages from '@/small-components/ProductImages.vue'
// import Collection from '@/home-components/Collection.vue'
import Bcjsonld from '@/small-components/Bcjsonld'

export default {
  components: { BreadCrumb, NormalProduct2, Bcjsonld },
  metaInfo () {
    return {
      title: this.product.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.product.title }
      ]
    }
  },
  data: function () {
    return {
      mediaCdn: this.$store.state.mediaCdn,
      pid: this.$route.params.pid,
      isLoading: false,
      isSaving: false,
      productType: '',
      product: '',
      variants: [],
      variantGroup: null,
      productPrice: 0,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: null,
      price: 0,
      totalPrice: 0,
      breadCrumb: [],
      jsonld: null,
      error: null
    }
  },
  beforeMount () {
    this.itemDetails()
  },
  watch: {
    $route (to, from) {
      const hash = to.hash
      const fromHash = from.hash
      if ((!hash) && (!fromHash)) {
        this.pid = to.params.pid
        this.itemDetails()
      }
    }
  },
  methods: {
    itemDetails: async function () {
      this.isLoading = true
      const payload = {
        pid: this.pid,
        user: this.$store.state.user
      }
      await axios.post('api/template.php', payload).then(response => {
        if (response.data.error === 'none') {
          this.productType = response.data.productType
          this.breadCrumb = response.data.bc
          this.product = response.data.product
          this.isLoading = false
          this.$fnc.pageView(this)
          let images = null
          if (this.product.images) {
            images = this.product.images.map(
              x => {
                return this.$store.state.mediaCdn + '1200_' + x
              }
            )
          }
          const jsonld = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: this.product.title,
            image: images,
            description: this.product.description,
            sku: this.product.sku,
            gtin13: this.product.barcode,
            brand: {
              '@type': 'Brand',
              name: this.product.brandName
            },
            offers: {
              '@type': 'Offer',
              url: window.location.href,
              priceCurrency: 'TRY',
              price: this.product.price,
              priceValidUntil: this.product.validUntil,
              itemCondition: 'https://schema.org/NewCondition',
              availability: 'https://schema.org/InStock',
              seller: {
                '@type': 'Organization',
                name: this.$store.state.baseUrl
              }
            }
          }
          this.jsonld = jsonld
          this.error = null
        } else {
          this.error = response.data.error
          setTimeout(() => {
            this.$router.push({ name: '404', params: { catchAll: this.$i18n.t(this.$fnc.seo(response.data.error)) } })
          }, 400)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.yedekRow {
  display:grid; grid-template-columns: 130px auto; align-items:center;margin-bottom:12px;border:1px solid #ccc;background:#fff;
  padding: 5px;
  p {
    color:#555
  }
}
.yedekRow:hover {
  p {
    color:var(--color2);
    text-decoration: underline;
  }
}
.pContainer {
  display:grid;
  grid-template-columns: 4fr 3fr;
  gap: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width:768px) {
  .pContainer {
    grid-template-columns: 1fr;
    gap:12px;
  }
}
</style>
