<template>
  <div>
    <div class="footerContainer">
      <footer class="container" v-if="info.companyInfo">
        <div class="ft">
          <p>{{ $t('Kategoriler') }}</p>
          <router-link v-for="item in info.menu" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }}</router-link>
        </div>
        <div class="ft">
          <p>{{ $t('Kurumsal') }}</p>
          <router-link v-for="item in info.kurumsal" :key="item.id" :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: item.cat_id }}">{{ item.title }}</router-link>
        </div>
        <div class="ftr">
          <p>{{ $t('Bizi Takip Edin') }}</p>
          <a v-for="item in info.socials" :key="item.id" rel="noopener" :href="item.paramValue" target="_blank">{{ item.paramTitle }}</a>
        </div>
        <div class="ftr">
          <p>{{ $t('İletişim') }}</p>
          <router-link :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_ADRES }}</router-link>
          <router-link :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_PHONE }}</router-link>
          <router-link v-if="info.companyInfo.PAGE_PHONE2" :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_PHONE2 }}</router-link>
          <router-link v-if="info.companyInfo.PAGE_PHONE3" :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_PHONE3 }}</router-link>
        </div>
      </footer>
      <div class="copy container">
        <a rel="noopener" target="_blank" href="https://www.kareiki.com/">2021 © Kare iKi</a>
      </div>
      <div class="whatsapp" v-if="info.companyInfo.PAGE_WHATSAPP">
        <a style="color:white;" target="_blank" :href="'https://api.whatsapp.com/send/?phone='+ info.companyInfo.PAGE_WHATSAPP +'&text=https%3A%2F%2Fgunduzmakina.com%2F&app_absent=0'">
          <i style="font-size:30px" class="fab fa-whatsapp"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info']
}
</script>

<style scoped lang="scss">
.whatsapp {
  position:fixed;right:15px;bottom:20px;width:50px;height:50px;border-radius:50%;background:#25d366;color:white;text-align:center;line-height:45px;
}
.footerContainer{
  background:#242628;
  padding-top: 30px;
  padding-bottom: 30px;
}
.copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-top:1px solid #535353;
  margin-top: 20px;
  padding-top: 20px;
  a {
    color:#bbbbbb;
  }
  a:hover {
    color:#fff;
  }
  font-size: 13px;
}

footer {
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  gap:0px;
  .ft, .ftr {
    p {
      font-size: 14px;
      color: #e4e4e4;
      font-weight: bold;
      padding-bottom: 4px;
    }
  }
}
a {
  font-size: 13px;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  color:#bbbbbb;
}
a:hover {
  color:#fff;
}
@media screen and (max-width: 768px) {
  footer {
    grid-template-columns: repeat(2, 1fr);
    .ft {
      margin-bottom: 42px;
    }
  }
  .copy {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    a {
      text-align: center;
    }
    img {
      width:calc(100% - 50px);
      margin-top: 16px;
    }
  }
}
</style>
